<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Nome</th>
          <th>Data do Ato</th>
          <th>Diário Estadual</th>
          <th>Número</th>
          <th>Assunto do Ato Administrativo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ato in atos" :key="ato.id">
          <td class="topo-card">{{ toUppercase(ato.tipo) }}</td>
          <td data-label="Nome:">{{ ato.nome_pessoa }}</td>
          <td data-label="Data do Ato:">{{ ato.data_ato }}</td>
          <td data-label="Diário:">
            <a
              :href="`https://diariooficial.abc.go.gov.br/portal/edicoes/download/${ato.id_download_diario}`"
              v-if="ato.id_download_diario"
              target="_blank"
              >{{ ato.data_diario }}</a
            >
            <span v-if="!ato.id_download_diario">{{ ato.data_diario }}</span>
          </td>
          <td data-label="Número:">{{ ato.numero }}</td>
          <td data-label="Assunto do Ato Administrativo:">{{ ato.assunto }}</td>
        </tr>
        <tr v-if="!atos || atos.length == 0" class="sem-resultados">
          <td colspan="6">Nenhum resultado encontrado</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "TabelaResultadosAtoAdmin",
  props: {
    atos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toUppercase: function (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.resultados {
  table {
    border-collapse: collapse;
    width: 100%;
    a {
      cursor: pointer;
      color: #375a3a;
    }
    thead {
      border-bottom: solid #ffa900 0.15rem;
      color: #0a4a22;
      th {
        margin: 0rem 2rem;
      }
      th:first-child {
        text-align: left;
        width: 8%;
      }
      th:nth-child(2) {
        text-align: center;
        width: 22%;
      }
      th:nth-child(3) {
        text-align: center;
        width: 10%;
      }
      th:nth-child(4) {
        text-align: center;
        width: 10%;
      }
      th:nth-child(5) {
        text-align: center;
        width: 10%;
      }

      th:nth-child(6) {
        text-align: center;
        width: 15%;
      }
    }
    tbody {
      tr {
        margin: 1rem;
        td {
          padding: 0.5rem;
          font-size: 1rem;
        }
        td:nth-child(1) {
          text-align: left;
        }
        td:nth-child(2) {
          text-align: left;
        }
        td:nth-child(3) {
          text-align: center;
        }
        td:nth-child(4) {
          text-align: center;
        }
        td:nth-child(5) {
          text-align: center;
        }
        td:nth-child(6) {
          text-align: center;
        }
        .topo-card {
        }
      }
      tr:nth-child(even) {
        background-color: #edf2ee;
      }
      .sem-resultados {
        background-color: transparent !important;
        text-align: center !important;
        height: 5rem;
        color: rgb(73, 73, 73);
        font-style: oblique;
        td {
          text-align: center !important;
        }
      }
    }
    .botao {
      width: 7rem;
      padding-bottom: 1rem;
      text-align: center;
      margin-top: 1rem;
      button {
        background-color: transparent;
        font-family: "Poppins";
        color: #0a4a22;
        border: #0a4a22 solid 0.15rem;
        border-radius: 0.2rem;
        padding: 0rem 1rem;
        font-size: 1rem;
        cursor: pointer;
      }
    }
  }
}
.alto-contraste {
  .resultados {
    table {
      thead {
        border-bottom: solid #ffa900 0.15rem;
        color: #ffa900 !important;
      }
      tbody {
        color: white;
        tr:nth-child(even) {
          background-color: #202020;
        }
        .sem-resultados {
          color: white;
        }
        a {
          color: rgb(0, 169, 211);
        }
      }
    }
  }
}
@media screen and (max-width: 64rem) {
  .resultados {
    table {
      width: calc(100% - 2rem);
      tbody {
        background-color: transparent;
      }
      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        margin: 1rem;
        display: block;
        margin-bottom: 1em;
        background-color: #edf2ee;
        padding-bottom: 0.5rem;
        width: 100% !important;
        td {
          padding: 0rem !important;
          margin: 0.5rem 1rem;
          font-size: 0.9rem !important;
        }
        td:nth-child(2) {
          text-align: left !important;
        }
        td:nth-child(3) {
          text-align: left !important;
        }
        td:nth-child(4) {
          text-align: left !important;
        }
        td:nth-child(5) {
          text-align: left !important;
          max-width: 50rem;
        }
        td:nth-child(6) {
          text-align: left !important;
        }
        .acao {
          display: none;
        }
      }
      tr:nth-child(even) td {
        display: block;
        margin-bottom: 0.5em;
        background-color: transparent;
      }

      td {
        display: block;
        min-height: 1.3rem;
        margin: 0.3rem 0.5rem;
        img {
          height: 1.5rem;
          margin: 0rem 1rem;
        }
      }

      td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        color: #0a4a22;
        font-family: "Poppins-Medium";
        margin-right: 0.5rem;
      }
      .topo-card {
        background-color: #375a3a !important;
        font-family: "Poppins";
        color: white;
        text-align: center;
        margin: 0rem 0rem 1rem 0rem !important;
        text-align: center !important;
        padding: 0.5rem 0.5rem !important;
        text-transform: uppercase;
      }
      .exibir-mais {
        margin-top: -1rem;
        color: black;
        text-align: left;
      }
    }
  }
  .alto-contraste {
    tr {
      background-color: #202020 !important;
    }
    table {
      td::before {
        color: #c2c2c2;
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="centro">
      <h1>Legislação</h1>
      <BuscaSimplesCards titulo="Consultas Rápidas" :cards="cardsLegislacao" />
    </div>
    <div
      class="pesquise"
      v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
    >
      <div class="centro">
        <div>
          <h2>Pesquise aqui:</h2>
          <div class="item row1">
            <BuscaSimples
              v-model="termo"
              contexto="da legislação"
              @busca-simples="fazerBuscaSimples"
            />
          </div>
          <OperadoresBusca @adicionar-operador="adicionarOperadorTermo" />
          <div class="botao">
            <button type="button" @click="tooglePesquisaAvancada">
              Pesquisa avançada
              <span>
                <template v-if="!exibirPesquisaAvancada">&#9660;</template>
                <template v-else>&#9650;</template>
              </span>
            </button>
          </div>
          <transition name="slide">
            <form
              v-if="exibirPesquisaAvancada"
              @submit.prevent="fazerBuscaAvancada"
              @keydown.enter.prevent="fazerBuscaAvancada"
            >
              <div class="avancado">
                <div class="row2">
                  <div class="item">
                    <label>Tipo</label>
                    <multiselect
                      v-model="tiposSelecionados"
                      :options="tipos_legislacoes"
                      :multiple="true"
                      :taggable="true"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Escolha opções..."
                      label="nome"
                      track-by="id"
                      class="multiselect-font font-black"
                    />
                  </div>
                  <div class="row2mobile">
                    <div class="item">
                      <label>Nº da Legislação</label>
                      <input type="text" v-model="filtro.numero" />
                    </div>
                    <div class="item">
                      <label>Ano da Legislação</label>
                      <input type="number" v-model="filtro.ano" />
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="grupo">
                    <p>Data da Legislação</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_legislacao"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_legislacao"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                  <div class="grupo">
                    <p>Data do Diário</p>
                    <div class="data">
                      <div class="item">
                        <label>De</label>
                        <date-picker
                          v-model="filtro.periodo_inicial_diario"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                      <div class="item">
                        <label>Até</label>
                        <date-picker
                          v-model="filtro.periodo_final_diario"
                          :formatter="datepickerFormatter"
                        ></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <label>Autor (A partir de 2019)</label>
                    <input type="text" v-model="filtro.autor" />
                  </div>
                  <div class="item">
                    <label>Categoria</label>
                    <multiselect
                      v-model="categoriaSelecionada"
                      :options="categoriasFormatadas"
                      :multiple="false"
                      :taggable="true"
                      :searchable="true"
                      :show-labels="false"
                      placeholder="Escolha opções..."
                      label="nome"
                      track-by="id"
                    >
                      <template #option="{ option }">
                        <div
                          :class="[
                            option.isOutras
                              ? 'categoria-nivel-' + (option.nivel + 1)
                              : 'categoria-nivel-' + option.nivel,
                          ]"
                          class="multiselect-font"
                        >
                          {{ option.nome }} ({{ option.quantidade }})
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="row2">
                  <div class="item">
                    <div class="gridbox-1">
                      <div class="input">
                        <label>Texto</label>
                        <input
                          id="inputTexto"
                          type="text"
                          v-model="filtro.conteudo"
                        />
                      </div>
                      <OperadoresBusca
                        @adicionar-operador="adicionarOperadorTexto"
                      />
                    </div>
                  </div>
                  <div class="item">
                    <div class="gridbox-1">
                      <div class="input">
                        <label>Ementa</label>
                        <input
                          id="inputEmenta"
                          type="text"
                          v-model="filtro.ementa"
                        />
                      </div>
                      <OperadoresBusca
                        @adicionar-operador="adicionarOperadorEmenta"
                      />
                    </div>
                  </div>
                  <div></div>
                  <div class="botoes">
                    <button
                      title="Limpar"
                      @click="limparFiltro()"
                      type="button"
                    >
                      Limpar
                    </button>
                    <button title="Pesquisar" type="submit">Buscar</button>
                  </div>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="seta">
          <img
            src="../../assets/images/triangulo-form.png"
            class="triangulo t-branco"
            alt=" "
          />
          <img
            src="../../assets/images/triangulo-form-black.png"
            class="triangulo t-preto"
            alt=" "
          />
        </div>
      </div>
    </div>
    <div class="centro campo-resultados" id="resultados">
      <div class="nav-resultados">
        <div class="first-flex">
          <h2>Resultados da Pesquisa</h2>
          <div
            class="dados-abertos"
            v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
          >
            <p>
              <span>Dados Abertos:</span>
              <a @click="abrirDadosAbertos('json')">JSON</a>
              <a @click="abrirDadosAbertos('csv')">CSV</a>
            </p>
          </div>
        </div>
        <div class="second-flex">
          <ParametrosBusca
            :funcaoBuscaSimples="fazerBuscaSimples"
            :funcaoBuscaAvancada="fazerBuscaAvancada"
            :termo="termo"
            :tipos="tipos_legislacoes"
            :categorias="categorias_legislacao"
            :filtro="filtro"
            contexto="legislacao"
            v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
          />
          <div
            class="ordenacao"
            v-show="
              !carregando &&
              !erro &&
              !carregandoFiltros &&
              !erroFiltros &&
              termo
            "
          >
            <label for="ordenarPor">Ordenar por: </label>
            <select
              id="ordenarPor"
              v-model="ordenarPor"
              @change="atualizarOrdenacao"
            >
              <option value="data">Data</option>
              <option value="relevancia">Relevância</option>
            </select>
          </div>
        </div>
        <div class="third-flex">
          <div
            class="nresultados"
            v-show="!carregando && !erro && !carregandoFiltros && !erroFiltros"
          >
            <p>
              <strong>{{ totalResultadosTexto }}</strong>
              {{
                totalResultadosTexto === 1
                  ? ` resultado encontrado`
                  : ` resultados encontrados`
              }}
            </p>
          </div>
          <div class="npagina" v-show="!carregando && !carregandoFiltros">
            <label>Resultados por Página</label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <TabelaResultadosLegislacao
          :legislacoes="legislacoes"
          v-if="!carregando && !erro && !carregandoFiltros && !erroFiltros"
        ></TabelaResultadosLegislacao>

        <Loading v-if="carregando || carregandoFiltros" size="medium"></Loading>
        <ErroResultados
          v-if="(!carregando || !carregandoFiltros) && (erro || erroFiltros)"
        ></ErroResultados>

        <paginate
          v-if="!erro && !carregando"
          v-model="pagina"
          :page-count="pageCount"
          :click-handler="mudouPagina"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
          :container-class="'className'"
          active-class="active-page"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<script>
// Bibliotecas
import { format, parseISO } from "date-fns";
import Paginate from "vuejs-paginate";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import Multiselect from "vue-multiselect";

// Componentes
import TabelaResultadosLegislacao from "../../components/pesquisa_legislacao/TabelaResultadosLegislacao";
import Loading from "../../components/Loading";
import ErroResultados from "../../components/ErroResultados";
import ParametrosBusca from "../../components/ParametrosBusca";
import BuscaSimples from "../../components/BuscaSimples.vue";
import OperadoresBusca from "../../components/OperadoresBusca.vue";

// API, Mixins e helpers
import LegislacaoApi from "../../services/LegislacaoApi";
import TipoLegislacaoApi from "../../services/TipoLegislacaoApi";
import CategoriaLegislacaoApi from "../../services/CategoriaLegislacaoApi";
import { datepickerFormatter } from "../../helpers/datepickerFormatter";
import { mixinLegislacao } from "../../mixins/mixinLegislacao";
import tryParseInt from "../../helpers/tryParseInt";
import metaDados from "../../helpers/metaDados";

export default {
  name: "PesquisaLegislacao",
  metaInfo() {
    return {
      title: "Pesquisa a Legislação",
      titleTemplate: "%s - Casa Civil do Estado de Goiás",
      meta: [
        {
          vmid: "description",
          name: "description",
          property: "description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
        },
        {
          vmid: "twitter:description",
          name: "twitter:description",
          property: "twitter:description",
          content:
            "Página de acesso e pesquisa a toda legislação e atos normativos do Governo do Estado de Goiás.",
        },
        {
          vmid: "twitter:title",
          name: "twitter:title",
          property: "twitter:title",
          content: "Pesquisa a Legislação - Casa Civil do Estado de Goiás",
        },
        ...metaDados(this.$route),
      ],
    };
  },
  mixins: [mixinLegislacao],
  data() {
    return {
      legislacoes: [],
      maisRecentes: [],
      carregando: true,
      erro: false,
      carregandoFiltros: true,
      erroFiltros: false,
      totalResultados: null,
      totalResultadosTexto: null,
      pagina: 1,
      qtd_por_pagina: 10,
      termo: null,
      ordenarPor: null,
      solo: null,
      filtro: {
        numero: null,
        conteudo: null,
        tipo_legislacao: [],
        categoria_legislacao: null,
        ementa: null,
        autor: null,
        ano: null,
        periodo_inicial_legislacao: null,
        periodo_final_legislacao: null,
        periodo_inicial_diario: null,
        periodo_final_diario: null,
      },
      tipos_legislacoes: [],
      tiposSelecionados: [],
      categorias_legislacao: [],
      categorias_legislacao_filtradas: [],
      categoriaSelecionada: null,
      exibirPesquisaAvancada: tryParseInt(this.getParam("busca_avancada")) == 1,
      datepickerFormatter,
    };
  },
  components: {
    TabelaResultadosLegislacao,
    Paginate,
    DatePicker,
    Loading,
    ErroResultados,
    ParametrosBusca,
    Multiselect,
    BuscaSimples,
    OperadoresBusca,
  },
  created() {
    this.legislacaoApi = new LegislacaoApi();

    this.tipoLegislacaoApi = new TipoLegislacaoApi();
    this.categoriaLegislacaoApi = new CategoriaLegislacaoApi();

    this.carregarQtdPorPagina();
    this.carregarValoresFiltro();
    this.carregarLegislacao();
  },
  watch: {
    $route: function (to, from) {
      const mudouQuery =
        to.query.numero != from.query.numero ||
        to.query.conteudo != from.query.conteudo ||
        to.query.tipo_legislacao != from.query.tipo_legislacao ||
        to.query.categoria_legislacao != from.query.categoria_legislacao ||
        to.query.ementa != from.query.ementa ||
        to.query.autor != from.query.autor ||
        to.query.ano != from.query.ano ||
        to.query.periodo_inicial_legislacao !=
          from.query.periodo_inicial_legislacao ||
        to.query.periodo_final_legislacao !=
          from.query.periodo_final_legislacao ||
        to.query.periodo_inicial_diario != from.query.periodo_inicial_diario ||
        to.query.periodo_final_diario != from.query.periodo_final_diario ||
        to.query.pagina != from.query.pagina ||
        to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
        to.query.termo != from.query.termo ||
        to.query.ordenarPor != from.query.ordenarPor;
      to.query.solo != from.query.solo;

      if (
        from.name == "PesquisaLegislacao" &&
        to.name == "PesquisaLegislacao" &&
        mudouQuery
      ) {
        this.carregarValoresFiltro();
        this.carregarLegislacao();
      }
    },
  },
  methods: {
    atualizarOrdenacao(event) {
      this.ordenarPor = event.target.value;
      this.fazerBuscaSimples(this.termo);
    },
    adicionarOperadorTermo(operador) {
      if (!this.termo) {
        this.termo = "";
      }
      this.termo += ` ${operador} `;
    },
    adicionarOperadorEmenta(operador) {
      if (!this.filtro.ementa) {
        this.filtro.ementa = "";
      }
      this.filtro.ementa += ` ${operador} `;
    },
    adicionarOperadorTexto(operador) {
      if (!this.filtro.conteudo) {
        this.filtro.conteudo = "";
      }
      this.filtro.conteudo += ` ${operador} `;
    },
    clicarBotaoAjuda() {
      var conteudoAjuda = document.getElementsByClassName("info-tooltip");
      if (conteudoAjuda[0].style.display == "block") {
        conteudoAjuda[0].style.display = "none";
      } else {
        conteudoAjuda[0].style.display = "block";
      }
    },
    mudouQtdPagina: function (event) {
      const novaQtdPagina = parseInt(event.target.value);
      if (novaQtdPagina < this.qtd_por_pagina) {
        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(this.pagina);
      } else {
        let novaPagina = Math.ceil(
          (this.qtd_por_pagina * this.pagina) / novaQtdPagina
        );

        this.qtd_por_pagina = novaQtdPagina;
        this.mudouPagina(novaPagina);
      }
    },
    getParam: function (name) {
      if (this.$route && this.$route.query) {
        return this.$route.query[name];
      }
      return null;
    },
    fazerBuscaSimples: function (termo) {
      this.$router.push({
        name: "PesquisaLegislacao",
        query: {
          termo: termo,
          ordenarPor: this.ordenarPor,
          pagina: 1,
          qtd_por_pagina: this.qtd_por_pagina,
        },
      });
      window.location.reload();

      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false,
      });
    },
    fazerBuscaAvancada: function (event, flag) {
      event.preventDefault();

      if (flag == true) {
        this.filtro.tipo_legislacao = [];
        this.tiposSelecionados = [];
        this.filtro.categoria_legislacao = null;
        this.categoriaSelecionada = null;
      } else {
        if (this.tiposSelecionados && this.tiposSelecionados.length > 0) {
          this.filtro.tipo_legislacao = this.tiposSelecionados.map(
            (tipo) => tipo.id
          );
        }

        if (this.categoriaSelecionada) {
          if (this.categoriaSelecionada.isOutras) {
            this.filtro.categoria_legislacao = parseInt(
              this.categoriaSelecionada.id.match(/\d+/)[0],
              10
            );
          } else {
            this.filtro.categoria_legislacao = this.categoriaSelecionada.id;
          }
        }
      }

      if (this.categoriaSelecionada && this.categoriaSelecionada.isOutras) {
        this.$router.push({
          name: "PesquisaLegislacao",
          query: {
            ...this.filtro,
            ...this.convertePeriodosDatasParaString(),
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
            busca_avancada: 1,
            solo: true,
          },
        });
        window.location.reload();
      } else {
        this.$router.push({
          name: "PesquisaLegislacao",
          query: {
            ...this.filtro,
            ...this.convertePeriodosDatasParaString(),
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
            busca_avancada: 1,
          },
        });
        window.location.reload();
      }

      this.$scrollTo("#resultados", 700, {
        offset: -100,
        cancelable: false,
      });
    },
    mudouPagina: function (page) {
      this.pagina = page;
      this.ordenarPor = this.getParam("ordenarPor");
      this.solo = this.getParam("solo");
      this.termo = this.getParam("termo");

      const filtro = {
        numero: this.getParam("numero"),
        conteudo: this.getParam("conteudo"),
        tipo_legislacao: this.getParam("tipo_legislacao"),
        categoria_legislacao: this.getParam("categoria_legislacao"),
        ementa: this.getParam("ementa"),
        autor: this.getParam("autor"),
        ano: this.getParam("ano"),
        periodo_inicial_legislacao: this.getParam("periodo_inicial_legislacao"),
        periodo_final_legislacao: this.getParam("periodo_final_legislacao"),
        periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
        periodo_final_diario: this.getParam("periodo_final_diario"),
      };

      this.$router.push({
        name: "PesquisaLegislacao",
        query: {
          ...filtro,
          termo: this.termo,
          pagina: this.pagina,
          qtd_por_pagina: this.qtd_por_pagina,
          ordenarPor: this.ordenarPor,
          solo: this.solo,
          busca_avancada: this.getParam("busca_avancada"),
        },
      });
      this.$scrollTo("#resultados", 0, {
        offset: -100,
        cancelable: false,
      });
    },
    carregarValoresFiltro: async function () {
      try {
        if (
          !this.carregando &&
          !this.erro &&
          this.tipos_legislacoes &&
          this.tipos_legislacoes.length > 0
        ) {
          return;
        }

        this.carregandoFiltros = true;
        this.erroFiltros = false;

        this.tipos_legislacoes =
          await this.tipoLegislacaoApi.getTiposLegislacao();

        if (this.filtro.tipo_legislacao) {
          const filtros = Array.isArray(this.filtro.tipo_legislacao)
            ? this.filtro.tipo_legislacao
            : [this.filtro.tipo_legislacao];

          this.tiposSelecionados = this.tipos_legislacoes.filter((tipo) =>
            filtros.some((filtro_tipo) => filtro_tipo == tipo.id)
          );
        } else {
          this.tiposSelecionados = [];
        }

        var categorias =
          await this.categoriaLegislacaoApi.getCategoriasLegislacao();
        this.categorias_legislacao = categorias;
        this.categorias_legislacao_filtradas = this.categorias_legislacao;

        if (this.filtro.categoria_legislacao) {
          const filtros = Array.isArray(this.filtro.categoria_legislacao)
            ? this.filtro.categoria_legislacao
            : [this.filtro.categoria_legislacao];

          const selecionados = this.buscarCategorias(
            this.categoriasFormatadas,
            filtros
          );

          this.categoriaSelecionada =
            selecionados.length > 0 ? selecionados[0] : null;
        }

        this.carregandoFiltros = false;
      } catch (err) {
        console.log("carregarValoresFiltro", err);
        this.carregandoFiltros = false;
        this.erroFiltros = true;
        this.tipos_legislacoes = [];
        this.categorias_legislacao = [];
        this.categorias_legislacao_filtradas = [];
      }
    },
    buscarCategorias(categorias, filtros) {
      let selecionados = [];

      this.getParam("solo") ? (filtros[0] = `outras-${filtros[0]}`) : filtros;

      categorias.forEach((categoria) => {
        if (
          filtros.some((filtro_categoria) => filtro_categoria == categoria.id)
        ) {
          selecionados.push(categoria);
        }

        if (categoria.children && Array.isArray(categoria.children)) {
          selecionados = selecionados.concat(
            this.buscarCategorias(categoria.children, filtros)
          );
        }
      });

      return selecionados;
    },
    carregarLegislacao: async function () {
      try {
        this.carregando = true;
        this.erro = false;

        this.qtd_por_pagina =
          this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

        this.armazenarQtdPorPagina();

        const pagina = this.getParam("pagina") || 1;
        this.pagina = parseInt(pagina);
        this.termo = this.getParam("termo") || "";
        this.ordenarPor = this.getParam("ordenarPor") || "data";
        this.solo = this.getParam("solo");

        this.filtro = {
          numero: this.getParam("numero"),
          conteudo: this.getParam("conteudo"),
          tipo_legislacao: this.getParam("tipo_legislacao"),
          categoria_legislacao: this.getParam("categoria_legislacao"),
          ementa: this.getParam("ementa"),
          autor: this.getParam("autor"),
          ano: this.getParam("ano"),
          ...this.convertPeriodosStringParaDatas(),
        };

        const buscaAvancada = tryParseInt(this.getParam("busca_avancada"));

        if (this.legislacaoApi.cancelTokens.getLegislacoes) {
          this.legislacaoApi.cancelTokens.getLegislacoes.cancel();
        }

        let data;
        if (buscaAvancada && buscaAvancada === 1) {
          data = await this.legislacaoApi.getLegislacoes({
            ...this.filtro,
            periodo_inicial_legislacao: this.getParam(
              "periodo_inicial_legislacao"
            ),
            periodo_final_legislacao: this.getParam("periodo_final_legislacao"),
            periodo_inicial_diario: this.getParam("periodo_inicial_diario"),
            periodo_final_diario: this.getParam("periodo_final_diario"),
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
            solo: this.solo,
          });
        } else {
          data = await this.legislacaoApi.getLegislacoes({
            termo: this.termo,
            qtd_por_pagina: this.qtd_por_pagina,
            ordenarPor: this.ordenarPor,
            solo: this.solo,
            page: this.pagina,
          });
        }

        this.legislacoes = data.resultados;
        this.totalResultados = data.total_resultados;
        this.totalResultadosTexto = data.total_resultados.toLocaleString();
        this.carregando = false;
      } catch (err) {
        if (this.legislacaoApi.isCancel(err)) {
          return;
        }
        console.log("carregarLegislacao", err);
        this.carregando = false;
        this.erro = true;
      }
    },
    limparFiltro: function () {
      this.filtro = {
        numero: null,
        conteudo: null,
        tipo_legislacao: [],
        categoria_legislacao: undefined,
        ementa: null,
        autor: null,
        ano: null,
        periodo_inicial_legislacao: null,
        periodo_final_legislacao: null,
        periodo_inicial_diario: null,
        periodo_final_diario: null,
      };
      this.categoriaSelecionada = null;
      this.tiposSelecionados = [];
    },
    convertePeriodosDatasParaString: function () {
      return {
        periodo_inicial_legislacao: this.filtro.periodo_inicial_legislacao
          ? format(this.filtro.periodo_inicial_legislacao, "yyyy-MM-dd")
          : null,
        periodo_final_legislacao: this.filtro.periodo_final_legislacao
          ? format(this.filtro.periodo_final_legislacao, "yyyy-MM-dd")
          : null,
        periodo_inicial_diario: this.filtro.periodo_inicial_diario
          ? format(this.filtro.periodo_inicial_diario, "yyyy-MM-dd")
          : null,
        periodo_final_diario: this.filtro.periodo_final_diario
          ? format(this.filtro.periodo_final_diario, "yyyy-MM-dd")
          : null,
      };
    },
    convertPeriodosStringParaDatas: function () {
      const periodo_inicial_legislacao = this.getParam(
        "periodo_inicial_legislacao"
      );
      const periodo_final_legislacao = this.getParam(
        "periodo_final_legislacao"
      );
      const periodo_inicial_diario = this.getParam("periodo_inicial_diario");
      const periodo_final_diario = this.getParam("periodo_final_diario");

      return {
        periodo_inicial_legislacao: periodo_inicial_legislacao
          ? parseISO(periodo_inicial_legislacao)
          : null,
        periodo_final_legislacao: periodo_final_legislacao
          ? parseISO(periodo_final_legislacao)
          : null,
        periodo_inicial_diario: periodo_inicial_diario
          ? parseISO(periodo_inicial_diario)
          : null,
        periodo_final_diario: periodo_final_diario
          ? parseISO(periodo_final_diario)
          : null,
      };
    },
    tooglePesquisaAvancada: function () {
      this.exibirPesquisaAvancada = !this.exibirPesquisaAvancada;
    },
    abrirDadosAbertos: function (format) {
      if (
        this.totalResultados == undefined ||
        this.totalResultados == null ||
        this.totalResultados > 1000
      ) {
        alert(
          "O download dos dados é limitado a 1000 registros. Refaça sua consulta utilizando os filtros disponíveis."
        );
      } else {
        const params = new URLSearchParams({
          numero: this.getParam("numero") || "",
          conteudo: this.getParam("conteudo") || "",
          tipo_legislacao: this.getParam("tipo_legislacao") || "",
          categoria_legislacao: this.getParam("categoria_legislacao") || "",
          ementa: this.getParam("ementa") || "",
          autor: this.getParam("autor") || "",
          ano: this.getParam("ano") || "",
          periodo_inicial_legislacao:
            this.getParam("periodo_inicial_legislacao") || "",
          periodo_final_legislacao:
            this.getParam("periodo_final_legislacao") || "",
          periodo_inicial_diario: this.getParam("periodo_inicial_diario") || "",
          periodo_final_diario: this.getParam("periodo_final_diario") || "",
          termo: this.getParam("termo") || "",
        }).toString();
        window.open(
          `${process.env.VUE_APP_BASE_API_URL}/api/v2/pesquisa/legislacoes/dados_abertos.${format}?${params}`,
          "_blank"
        );
      }
    },
    armazenarQtdPorPagina() {
      localStorage.setItem("qtd_por_pagina_legislacao", this.qtd_por_pagina);
    },
    carregarQtdPorPagina() {
      const qtd = localStorage.getItem("qtd_por_pagina_legislacao");
      if (qtd) {
        this.qtd_por_pagina = tryParseInt(qtd);
      }
    },
    getLabelItem: function (item) {
      if (item && !item.length) {
        return item.nome;
      } else if (item && item.length) {
        item.map((elemento) => {
          return elemento.nome;
        });
      }
    },
    filtrarCategoria: function (texto) {
      if (texto) {
        const res = this.categorias_legislacao.filter((hash) =>
          hash.nome
            .toLowerCase()
            .normalize("NFD")
            .includes(texto.toLowerCase().normalize("NFD"))
        );
        this.categorias_legislacao_filtradas = res;
      } else {
        this.categorias_legislacao_filtradas = this.categorias_legislacao;
      }
    },
    onCategoriaSelecionada(categoria) {
      this.categoriaSelecionada = categoria;
      this.filtro.categoria_legislacao = categoria.id;
    },
  },
  computed: {
    pageCount: function () {
      if (
        this.totalResultados != undefined &&
        this.totalResultados != null &&
        this.qtd_por_pagina != null &&
        this.qtd_por_pagina != undefined &&
        this.totalResultados > 0 &&
        this.qtd_por_pagina > 0
      ) {
        return Number(
          Math.ceil(this.totalResultados / this.qtd_por_pagina).toLocaleString(
            "pt-BR"
          )
        );
      }
      return 0;
    },
    categoriasFormatadas() {
      const flattenCategories = (categories, nivel = 0) => {
        return categories.reduce((acc, category) => {
          acc.push({
            id: category.id,
            nome: category.nome,
            nivel: nivel,
            quantidade: category.quantidade_total.toLocaleString("pt-BR"),
            isOutras: false,
          });

          if (category.children && category.children.length) {
            acc = acc.concat(flattenCategories(category.children, nivel + 1));
          }

          if (
            nivel < 2 &&
            category.children.length > 0 &&
            category.quantidade > 0
          ) {
            acc.push({
              id: `outras-${category.id}`,
              nome: "Outras",
              nivel: nivel,
              quantidade: category.quantidade.toLocaleString("pt-BR"),
              isOutras: true,
              parentId: category.id,
            });
          }

          return acc;
        }, []);
      };

      return flattenCategories(this.categorias_legislacao_filtradas);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.font-black {
  color: black;
  font-weight: bolder;
}
.multiselect-font {
  font-size: 0.9rem;
}
.categoria-nivel-0 {
  font-weight: bolder;
  padding: 0.3rem 0;
  color: black;
}
.categoria-nivel-1 {
  padding-left: 2rem;
  color: rgb(73, 73, 73);
  font-weight: 600;
}
.categoria-nivel-2 {
  padding-left: 4rem;
  color: rgb(78, 78, 78);
  font-weight: 500;
}

.busca-info {
  display: block;
  margin: 0 0 1rem 0;
  padding: 0;
  .info-texto {
    margin: 0.5rem 0;
    color: #ffa900;
    font-size: 0.93rem;
    span {
      font-weight: bold;
    }
  }
}

.tooltip {
  display: flex;
  width: max-content;
  margin: 1rem 0 1rem 0;
  .button {
    margin: 0.3rem 0.7rem 0 0;
    font-size: 0.9rem;
    background-color: #edf2ee;
    border: none;
    border-radius: 0.3rem;
    padding: 0.3rem 0.5rem;
    box-shadow: 1px 2px 1px #ffa900;
    cursor: pointer;
  }
  .button:hover {
    box-shadow: 0px 0px 0px;
    padding: 0.27rem 0.47rem;
    font-size: 0.87rem;
    background-color: #ffa900;
  }
  .container-tooltip {
    position: relative;
  }
  .modal {
    display: block;
    width: 16rem;
    z-index: 1000;
    position: absolute;
    max-width: 20rem;
    font-size: 0.9rem;
    background-color: #edf2ee;
    box-shadow: 3px 5px 5px #ffa900;
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;

    span {
      font-weight: bold;
    }
  }
  .modal-1 {
    display: none;
  }
  .modal-2 {
    display: none;
  }
  .modal-3 {
    display: none;
  }
  .modal-4 {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 426px) and (max-width: 1024px) {
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 13%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 31%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 60%;
    }
  }
  .info-tooltip {
    width: 60vw;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 10px;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 20vw;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5.5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 25vw;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .modal {
    width: 22rem !important;
  }
  .modal-1 {
    left: -0.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 0%;
    }
  }
  .modal-2 {
    left: -2.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 11%;
    }
  }
  .modal-3 {
    left: -5.5rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 25%;
    }
  }
  .modal-4 {
    left: -9rem;
    top: 5vh;

    &::after {
      content: "";

      width: 0;
      height: 0;

      position: absolute;

      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #edf2ee;

      top: -4%;
      left: 50%;
    }
  }
  .info-tooltip {
    width: max-content;
    height: max-content;
    position: absolute;
    transform: translateX(-50%);
    background-color: #ffa900;
    padding: 1rem;
    border-radius: 4px;
    display: none;
    z-index: 1;
    margin-top: 0.5rem;
    left: 15vw;
  }

  .info-content {
    color: black;
    .exemplo {
      color: #375a3a;
      font-weight: bold;
    }
  }

  div.info-content h2 {
    color: #375a3a;
    border-bottom: #375a3a solid 0.15rem;
    margin-bottom: 1rem;
  }

  .info-content p {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}

h1 {
  font-family: "Poppins-Bold";
  color: #2c3f2b;
  padding-left: 0.5rem;
  border-left: #ffa900 solid 0.15rem;
  margin: 2.5rem 0rem 1.5rem 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
}
.pesquise {
  background-color: #375a3a;
  padding: 0.5rem 1rem 1.5rem;
  label {
    color: white;
    font-size: 0.9rem;
  }
  input {
    border: none;
    width: calc(100% - 0.8rem);
    height: 2rem;
    border-radius: 0.2rem;
    border: none;
    padding-left: 0.5rem;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.03rem;
    color: black;
  }
  form {
    .campo-preenchido {
      background-color: #fffed4;
      // border: solid .15rem #ffa900;
      // box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -moz-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
      // -webkit-box-shadow: 0rem 0rem 0rem 0.13rem #ffa900;
    }
  }

  .botao {
    button {
      background-color: transparent;
      border: none;
      font-size: 0.9rem;
      font-family: "Poppins";
      color: #ffa900;
      padding: 0rem;
      letter-spacing: 0.03rem;
      span {
        font-size: 0.9rem;
      }
    }
    button:focus {
      box-shadow: none;
      outline: none;
      cursor: pointer;
    }
  }
  .botoes {
    float: right;
    margin: 1rem 0.2rem 1rem auto;
    button {
      margin-left: 1rem;
      padding: 0.1rem 2rem;
      font-family: "Poppins-Medium";
      font-size: 0.9rem;
    }
    button:first-child {
      background: transparent;
      border: white solid 0.15rem;
      color: white;
      padding: 0.1rem 2rem;
      cursor: pointer;
    }
    button:last-child {
      background-color: #ffa900;
      color: #2c3f2b;
      border: solid 0.15rem #ffa900;
      cursor: pointer;
    }
  }

  select {
    width: calc(100% - 0.2rem);
    height: 2rem;
    border: none;
    border-radius: 0.2rem;
    padding-left: 0.5rem;
    background-color: white;
    font-family: "Poppins";
    font-size: 1rem;
    letter-spacing: 0.04rem;
  }

  h2 {
    color: white;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    display: inline-table;
    border-bottom: #ffa900 solid 0.15rem;
    margin: 0rem;
    padding-right: 1rem;
    width: fit-content;
  }
  .row2 {
    display: grid;
    .row2mobile {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }
  .grupo {
    border: #ffa900 solid 0.15rem;
    margin: 0.5rem 0rem;
    padding: 0.5rem 0.5rem;
    border-radius: 0.5rem;
    .data {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
      .mx-datepicker {
        width: 100%;
      }
    }
    p {
      margin: 0rem;
      padding: 0rem;
      color: white;
    }
  }
  .item {
    display: grid;
    margin: 0.5rem 0rem;
    height: fit-content;
  }
  .seta {
    img {
      width: 5rem;
      position: absolute;
      right: 1.5rem;
      margin-top: 0rem;
    }
    .t-preto {
      display: none;
    }
  }
}
.campo-resultados {
  margin: 1rem;
  h2 {
    color: #2c3f2b;
    font-family: "Poppins-ExtraLight";
    font-size: 1.1rem;
    border-bottom: #ffa900 0.15rem solid;
    margin: 1.5rem 0rem 0rem 1rem;
    display: table;
    padding-right: 1rem;
  }
  .dados-abertos {
    padding-left: 0.5rem;
    width: 15rem;
    margin: 1rem 1rem 0rem 0rem;
    p,
    span {
      margin: 0rem;
      font-size: 0.9rem;
      font-family: "Poppins-ExtraLight";
      a {
        margin: 0rem 0.35rem 0rem 1rem;
        text-decoration: none;
        color: #2c3f2b;
        font-family: "Poppins-Medium";
        cursor: pointer;
      }
    }
    p {
      background-color: #edf2ee;
      padding-left: 0.5rem;
      height: 1.3rem;
    }
  }
  .nav-resultados {
    margin-bottom: 2rem;
    display: grid;

    .first-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;

      h2 {
        margin: 0;
      }

      .dados-abertos {
        padding-left: 0;
        margin: 0;
      }
    }

    .second-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.3rem 0;

      .ordenacao {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    .third-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;

      .nresultados {
        margin: 0;
        width: fit-content;
        height: fit-content;

        p {
          display: block;
          width: fit-content;
          height: fit-content;
          font-size: 0.9rem;
          margin: 0rem;
          strong {
            font-family: "Poppins-Medium";
          }
        }
      }

      .npagina {
        margin: 0;

        label {
          font-family: "Poppins-Medium";
          font-weight: 900;
          margin-right: 0.5rem;
          color: #2c3f2b;
          font-size: 0.9rem;
        }
        select {
          border: none;
          background-color: #edf2ee;
          font-size: 0.9rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

@media screen and (min-width: 31rem) {
  .nav-resultados {
    margin: 0 0.5rem 2rem 0.5rem;
    display: grid;

    .first-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem 0;

      h2 {
        margin: 0;
      }

      .dados-abertos {
        padding-left: 0;
        margin: 0;
      }
    }

    .second-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.3rem 1rem;

      .ordenacao {
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    .third-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0.3rem 1rem;

      .nresultados {
        margin: 0;

        p {
          font-size: 0.9rem;
          margin: 0rem 0rem -2rem 1rem;
          strong {
            font-family: "Poppins-Medium";
          }
        }
      }

      .npagina {
        margin: 0;

        label {
          font-family: "Poppins-Medium";
          font-weight: 900;
          margin-right: 0.5rem;
          color: #2c3f2b;
          font-size: 0.9rem;
        }
        select {
          border: none;
          background-color: #edf2ee;
          font-size: 0.9rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}
@media screen and (min-width: 64rem) {
  h1 {
    margin-left: 0rem;
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.3rem !important;
  }

  .pesquise {
    padding-bottom: 2rem !important;

    h2 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .label-busca {
      text-align: left;
      margin-bottom: 0.3rem;
    }
    .row2 {
      grid-template-columns: calc(50% - 1%) calc(50% - 1%);
      gap: 2%;
      .item {
        input {
          padding-right: 0.25rem;
        }
        .gridbox-1 {
          display: grid;
        }
      }
    }
    .grupo {
      padding: 0.5rem 2rem;
    }
    .botoes {
      margin-right: 0rem;
      margin-top: 0.5rem;
      button {
        font-size: 1rem;
        padding: 0.1rem 4rem !important;
        border-radius: 0.2rem;
      }
    }
    .seta {
      img {
        right: 4.7rem;
        margin-top: 0.5rem;
      }
    }
  }
  .campo-resultados {
    h2 {
      margin-left: 0rem;
    }
    .dados-abertos {
      padding-left: 0.5rem;
      width: 13rem;
      margin: -2rem 1rem 0rem auto;
      text-align: right;
      p,
      span {
        font-size: 0.8rem;
      }
    }
    .npagina {
      text-align: right;
      label {
        font-size: 0.8rem;
      }
    }
    .nresultados {
      max-width: 17rem;
      text-align: left;
      margin: 0rem 0rem 2rem;
      p {
        font-size: 0.8rem;
        margin: -1.5rem 0rem 0rem 0rem;
        text-align: left;
        strong {
          font-family: "Poppins-Medium";
        }
      }
    }
  }
}
.alto-contraste {
  .pesquise {
    background-color: #213b27;
    .seta {
      .t-branco {
        display: none;
      }
      .t-preto {
        display: block;
      }
    }

    .botoes {
      button:last-child {
        background-color: #ffa900;
        color: #0d2b10;
        font-family: "Poppins-Medium";
      }
    }
  }
  .nav-resultados {
    h2 {
      color: white;
    }
  }
  .npagina {
    label {
      color: white !important;
    }
  }
  .nresultados {
    color: white;
  }
}

/* Estilos para o campo de ordenação */
.ordenacao {
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.ordenacao label {
  color: #2c3f2b;
  font-size: 0.9rem;
  font-family: "Poppins-Medium";
  margin-right: 0.5rem;
}

.ordenacao select {
  height: 1.5rem;
  border: none;
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  background-color: #ffffff;
  font-family: "Poppins";
  font-size: 1rem;
  color: #2c3f2b;
  cursor: pointer;
  box-shadow: 0 0 0 0.15rem #ffa900;
  transition: background-color 0.3s, box-shadow 0.3s;
  appearance: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMmMzZjJiIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI2Ij48cGF0aCBkPSJNNSA2IDAgMGgxMGwtNSA2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 0.65rem;
  padding-right: 1.5rem;
}

.ordenacao select:hover {
  background-color: #edf2ee;
}

.ordenacao select:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem #ffa900;
}

@media screen and (max-width: 425px) {
  .ordenacao {
    flex-direction: column;
    align-items: flex-start;
  }
  .ordenacao label {
    margin-bottom: 0.8rem;
  }
  .ordenacao select {
    width: 100%;
  }
}

/* Estilos para o modo alto contraste */
.alto-contraste .ordenacao label,
.alto-contraste .ordenacao select {
  color: #ffffff;
}

.alto-contraste .ordenacao select {
  background-color: #2c3f2b;
  box-shadow: 0 0 0 0.15rem #ffffff;
}

.alto-contraste .ordenacao select:hover {
  background-color: #375a3a;
}
</style>
